import {
  getListByEId
} from "@/service/charge/index";
export default {
  //新增订单
  name: "refdetails",
  data() {
    return {
      refundDetails: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 10,
            begin: this.$moment('2019-05-01').format('YYYYMMDD'),
            end: this.$moment().add(1, 'day').format('YYYYMMDD'),
            unitId: sessionStorage.getItem('unitId'),
            status: -1,
            stageMark: 0,
            eId:'',
          },
          total: 0,
          results: [],
        },
      },
      previewVisible: false,
      previewImage: '',
      fileList: [],
    }
  },
  methods: {
    queryrefund() {
      this.fileList=[];
      this.refundDetails.list.query.stageMark=this.$route.query.key;
      this.refundDetails.list.query.eId=this.$route.query.eId;
      let  params= this.refundDetails.list.query;
      getListByEId(params).then(res => {
        if(res.data[0].attachment!=undefined){
          if(res.data[0].attachment.length>0){
            this.fileList=[ {
              uid: '-1',
              name: 'xxx.png',
              status: 'done',
              url:res.data[0].attachment,
            }]
          }
        }
        this.refundDetails.list.results = res.data;
      })
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
  },
  watch: {},
  created() { },
  mounted() {
    this.queryrefund();
  },
  activated() {
  }
};